
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OnlyNumberDirective } from './directive/only-number.directive';
import { AlphabetDirective } from './directive/alphabet.directive';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { CommonModule } from '@angular/common';
import {MatSidenavModule} from '@angular/material/sidenav';

import { FlexLayoutModule } from '@angular/flex-layout';
@NgModule({
    declarations: [
        OnlyNumberDirective,
        AlphabetDirective,
        NavigationComponent,
        FooterComponent
    ],
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatDividerModule,
        MatCardModule,
        MatDialogModule,
        TranslateModule,
        MatMenuModule,
        MatBadgeModule,
        MatToolbarModule,
        MatSidenavModule,
        CommonModule,
        FlexLayoutModule
        
    ],
    
    exports: [
        OnlyNumberDirective,
        AlphabetDirective,
        NavigationComponent,
        FooterComponent,
        FlexLayoutModule
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule{

}