import { InMemoryDbService } from 'angular-in-memory-web-api';

import {OrderFakeDb} from './order';

export class FakeDbService implements InMemoryDbService {
    createDb(): any {
        return {

            'orderCountry': OrderFakeDb.country,
            'orderCurrency': OrderFakeDb.currency,
            'orderTypeShipping': OrderFakeDb.type_shipping,
            'orderBankDetails': OrderFakeDb.bank_details,
            'orderSimulator': OrderFakeDb.simulator,
            'orderNumber': OrderFakeDb.order_number,
            'orderAccountsTranzfer': OrderFakeDb.accountsTranzfer,
            'orderConstancy': OrderFakeDb.constancy

        };
    }
}
