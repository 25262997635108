import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './modules/home/home.module';


import { AboutModule } from './modules/about/about.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';

import {HttpInterceptorImplement} from '../app/shared/interceptor/http-interceptort';
import { SharedModule } from './shared/shared.module';

import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import {FakeDbService} from './fake-db/fake-db.service';


import { CarouselModule } from 'ngx-owl-carousel-o';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    FormsModule,
    AboutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    SharedModule,
    //Fake DataBase
    InMemoryWebApiModule.forRoot(FakeDbService, {  
      delay: 0,
      passThruUnknownUrl: true
    }), 
    CarouselModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorImplement,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
