export const locale = {
    lang: 'en',
    data: {
        'Login': {
            'correo': 'Email',
            'google': 'Continue with Google',
            'lbl_correo': 'Enter a valid email',
            'contrasena': 'Enter your password',
            'lbl_contrasena': 'Enter a valid password',
            'btn_continuar': 'Continue',
            'btn_crear_cuenta': 'Create Account',
            'btn_olvidar_contrasena': 'Did you forget your password?',
            'btn_volver': 'Behind'
        },
        'OlvidasteContrasena': { 
            'titulo': 'Account recovery',
            'sumilla': 'Enter your email to recover your account',
            'correo': 'Email',
            'lbl_correo': 'Enter a valid email',
            'sumilla_confirmacion': 'We have sent you a message so that you can confirm your account to the following email',
            'btn_siguiente': 'Next',
            'titulo_cambio_contrasena': 'Change of password',
            'sumilla_cambio_contrasena': 'Enter your email to recover your account',
            'contrasena': 'Password',
            'confirmar_contrasena': 'Confirm Password',
            'codigo': 'Code',
            'lbl_codigo': 'Enter a code',
            'lbl_contrasena': 'Enter a valid password',
            'btn_continuar': 'Continue',
            'btn_volver': 'Behind',
            'confirmacion_correo': 'E-mail confirmation',
        },
        'CrearCuenta': {
            'nombre': 'Name',
            'apellido': 'Surname',
            'correo': 'Mail',
            'contrasena': 'Password',
            'confirmar_contrasena': 'Confirm Password',
            'terminos_condiciones_0': 'I accept the ',
            'terminos_condiciones_1': 'privacy policy ',
            'terminos_condiciones_2': 'of tranzfer-me',
            'btn_cancelar': 'Cancel',
            'btn_registrar': 'To register',
            'lbl_nombre': 'Please enter a valid name',
            'lbl_apellido': 'Please enter a valid last name',
            'lbl_correo': 'Enter a valid email',
            'lbl_contrasena': 'Enter a valid password',
            'lbl_confirmar_contrasena': 'Enter a valid password',
            'confirmacion_correo': 'E-mail confirmation',
            'lbl_confirmacion_correo': 'We have sent you a message so that you can confirm your account to the following email:',
            'ingresa_codigo': 'Enter a code',
            'btn_continuar': 'Confirm',
            'reenviar_codigo': 'Resend code'
        },
        'IngresaMontos':{
            'titulo': 'Enter Amounts',
            'envias': 'You ship from',
            'recibes': 'Your recipient receives in',
            'pais': 'Country',
            'monto': 'Amount',
            'divisa': 'Currency',
            'btn_aplicar': 'Apply',
            'costos': 'Costs',
            'inp_cupon': 'Enter it here',
            'comision': 'Shipping commission',
            'impuestos': 'Taxes',
            'descuentos':'Discounts',
            'otros_cargos': 'Other charges',
            'sub_total': 'Sub total',
            'total_convertir': 'Total to convert',
            'tipo_cambio': 'Exchange rate',
            'informativo': 'To complete your order you must enter the number of your accounts.',
            'enviar_dinero': 'From which account will you send your money?',
            'recibir_dinero': 'In which account will your money be received?',
            'descripcion': 'Description (optional)',
            'btn_orden': 'Register order',
            'btn_cancelar': 'Cancel',
            'agregar_cuenta': 'Add new shipping account',
            'agregar_cuenta_recibir_dinero': 'Add account to receive money',
            'tipo_envio':'Choose your shipping type',
            'codigo_promocional': 'Do you have a promotional code?',
            'datos_bancarios': 'Enter bank details',
            'cuenta_enviar': 'Select an account to send the money',
            'cuenta_recive':'Select an account that will receive your money',
            'titulo_dinero': 'Send money'
        },
        'SubeConstancia':{
            'orden': 'Your order number is:',
            'sumilla_1': 'Now you just have to transfer',
            'sumilla_2': 'To the following Tranzfer.me account:',
            'copiar': 'Copy',
            'transferencia': 'Have you already made the transfer?',
            'sube_voucher': 'Upload your boucher here',
            'btn_subir': 'Increase',
            'informativo': 'To complete your order you must take a screenshot of your proof',
            'tipo_cambio': 'Exchange rate',
            'tipo_envio': 'Shipping type',
            'destinatario': 'Your recipient will receive:',
            'btn_continuar': 'Continue',
            'btn_editar': 'Edit',
            'sube_constancia': 'Upload your constancy'
        },
        'ResumenOrden': {
            'orden': 'Order Status',
            'fecha_estimada': 'Estimated date of arriving',
            'resumen': 'Resume',
            'envias': 'Shipping',
            'recibes': 'You receive',
            'costos': 'Costs',
            'comision': 'Shipping commission',
            'impuestos': 'Taxes',
            'descuentos': 'Discounts',
            'total_convertir': 'Total to convert',
            'tipo_cambio': 'Exchange rate',
            'detalles_cuenta': 'Account details',
            'cuenta_origen': 'Origin account',
            'cuenta_destino': 'Destination account',
            'btn_ordenes': 'Go to my orders',
            'resumen_orden': 'Order summary',
            'estado_sumilla': 'We are validating the data and the proof of transfer.',
            'descripcion_orden': 'Description of the order'
        },
        'AgregarCuenta':{
            'banco': 'Bank',
            'pais': 'Country',
            'correo': 'Mail',
            'lbl_correo': 'Enter a valid email',
            'tipo_cuenta': 'Account type',
            'monedas': 'Currency',
            'numero_cuenta': 'Account number',
            'cci': 'CCI',
            'nombres': 'Names',
            'apellidos': 'Surnames',
            'alias': 'Aliases',
            'lbl_numero_cuenta': 'Enter an account number',
            'lbl_cci': 'Enter the CCI',
            'lbl_nombres': 'Enter your name',
            'lbl_apellidos': 'Enter your last name',
            'lbl_aliias': 'Alias',
            'check_cuenta': 'It is my personal account',
            'check_operaciones': 'Save for my next operations',
            'btn_cancelar': 'Cancel',
            'btn_cuenta': 'Cancelar',
            'cuenta_origen': 'Add source account'
        },
        'Ordenes':{
            'tab_creadas': 'Created',
            'tab_proceso': 'In progress',
            'tab_finalizadas': 'Completed',
            'btn_enviar': 'Send money',
            'envias': 'You ship from',
            'recibes': 'Your recipient receives in',
            'numero_orden': 'Order no.',
            'orden_registrada': 'Registered order',
            'orden_enviado': 'Sent',
            'orden_finalizado': 'Finished',
            'constancia': 'See record',
            'cancelar_orden': 'To cancel an order',
            'tipo_envio': 'Shipping type',
            'tipo_cambio': 'Exchange rate',
            'cuenta_origen': 'Root account',
            'cuenta_destino': 'Destination account',
            'fecha_estimada': 'Destination account',
            'modal_titulo': 'Order',
            'modal_sumilla': 'Are you sure to delete the record?',
            'modal_confirmar': 'Confirm',
            'modal_cancelar': 'Cancel',
            'ordenes': 'My orders'
        },
        'CuentasBancarias':{
            'titulo': 'Bank accounts',
            'tab_origen': 'Source',
            'tab_destino': 'Destination',
            'btn_cuenta': 'Add account',
            'cancelar_orden': 'To cancel an order',
            'nombre': 'Name',
            'cci': 'CCI',
            'tipo_cuenta': 'Account type',
            'tipo_moneda': 'Type of currency'
        },
        'Perfil': {
            'tab_cuenta': 'Account data',
            'tab_contrasena':'Password',
            'titulo': 'Hello',
            'datos_cuenta': 'Account data',
            'nombres_apellidos': 'Names and surnames',
            'correo': 'Email',
            'datos_personales': 'Personal information',
            'fecha_nacimiento': 'Date of birth',
            'pais': 'Country',
            'genero': 'Gender',
            'celular': 'Mobile',
            'btn_agregar': 'Add',
            'contrasena': 'Change Password',
            'contrasena_actual': 'Current password',
            'contrasena_nueva': 'New Password',
            'confirmar_contrasena': 'Confirm new password',
            'btn_contrasena': 'Change Password',
            'btn_cancelar': 'Cancel',
            'perfil': 'My profile',
            'lbl_nombres_apellidos': 'Enter first and last names',
            'btn_guardar': 'Keep',
            'lbl_pais': 'Select a country',
            'lbl_opcion': 'Select an option',
            'lbl_celular': 'Enter a cell phone number'
        },
        'CentroAyuda':{
            'titulo': 'Hello',
            'tab_enviar': 'Send money',
            'tab_seguridad': 'Security',
            'panel_1': {
                'panel': 'What is Tranzfer.me?',
                'text': 'We are a Startup that manages money transfer abroad. We work based on a collaborative economy formed by a group of people like you that allow us to reduce costs of the internacional money transfers.'
            },
            'panel_2': {
                'panel': 'What do I need to transfer?',
                'text': 'Both you and your beneficiary must have a bank account. In the case you or your beneficiary are in the US, we also recommend to have an active Zelle account.'
            },
            'panel_3': {
                'panel': 'Which are the steps?',
                'text':  '1. The sender must enter the amount of money at www.tranzfer.me' + 
                 '<br> 2. Indicate currencies (from Peru, USA or Brasil) to send and receive. The calculator will indicate the equivalent exchage that the recipient will receive.'+
                 '<br> 3. Click on "Send money" and you will be directed to a direct communication with us via WhatsApp.' +
                 '<br> 4. Complete all the final steps to make the transfer effective. '
            },
            'panel_4': {
                'panel': 'What kind of commission or shiphing method do you have?',
                'text':  'We have two shipping methods according to our daily limit: <br>' +
                 '- Normal shipping at a rate of 1.50% of the amount.<br>' +
                 '- Premium shipping at a rate of 3.00% of the amount.<br>' +
                 'The other method is for bigger amounts of the daily limit: <br>' +
                 '- Normal shipping at a rate of 5.00% of the amount.<br>'+
                 '- Premium shipping at a rate of 7.00% of the amount.'
            },
            'panel_5': {
                'panel': 'In which countries do you operate?',
                'text':  'We work with Peru, Brasil and the Unites States. Coming soon more countries in Latin America.'    
            },
            'panel_6': {
                'panel': 'On what exchange rate are they based? ',
                'text':  'We choose the cheapest exchange rate, based on different digital exchange houses.'    
            },
            'panel_7': {
                'panel': 'How long does it take for my money to arrive?',
                'text':  'The money reaches the destination within 24 hours (premium shipping) to 72 hours (normal shipping) maximum. The deadline is effective from the time that the money enters the bank account provided by Tranzfer.me. The time will depend on the time and the bank with wich the transaction is made.'    
            },
            'panel_8': {
                'panel': 'Can I make a deposit into my account? ',
                'text':  'After evaluation and identity validation.'    
            },
            'panel_9': {
                'panel': 'What are your opening hours?',
                'text':  'Our advisors will assist you during the following hours:  Monday to Friday from 9:00 am to 9:00 pm and Saturdays from 10:00 am to 1:00 pm. However, you can make your request at any time.'    
            },
            'panel_10': {
                'panel': 'Do I want someone from the Tranzfer.me team to help me?',
                'text':  'If you need any advice, do not hesitate to write us to our email tranzfers@tranzfer.me or through any of our social networks.'    
            },
            'panel_11': {
                'panel': 'What is the guarantee that the money will reach its destination? ',
                'text':  'We have return policies in case of any setback with the shipping process or if requested by the sender. Tranzfer.me assures the success of the shipment as long as the requested data is truthful and validated. To this day we have a 100% success rate in shipments.'
            },
            'panel_12': {
                'panel': 'Is it safe?',
                'text':  'We are a legally incorporated company (RUC 20603997922). We have security procedures, handling and encryption of personal data. You can contact us through any of our communication channels.'    
            },
            'informacion': 'If you need personalized help, do not hesitate to contact us by these means',
            'centro_ayuda': 'Help center'
        },
        'ValidarUsuario' : {
            'titulo': 'We want to validate that you are not a robot, please solve the following mathematical operation'
        }
    }
};
