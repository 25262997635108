import { TranslateService } from '@ngx-translate/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';

import * as _ from 'lodash';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  languages: any;
  selectedLanguage: any;
  mobileQuery: MediaQueryList;
  fillerContent = Array.from({length: 50}, () =>
  `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
   labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
   laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
   voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
   cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);

  private _mobileQueryListener: () => void;
  fillerNav = Array.from({length: 50}, (_, i) => `Nav Item ${i + 1}`);
   /**
     * Constructor
     *
     * 
     * @param {TranslateService} _translateService
     */
  constructor(
    private _translateService: TranslateService,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) { 
      this.languages = [
        {
            id   : 'en',
            title: 'English',
            flag : 'us'
        },
        {
            id   : 'br',
            title: 'Brazil',
            flag : 'br'
        },
        {
            id   : 'es',
            title: 'Perú',
            flag : 'pr'
        }
    ];

      this.mobileQuery = media.matchMedia('(max-width: 600px)');
      this._mobileQueryListener = () => changeDetectorRef.detectChanges();
      this.mobileQuery.addListener(this._mobileQueryListener);
    
  }

  ngOnInit(): void {
    this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});
    console.log(this.selectedLanguage);
  }


/**
     * Set the language
     *
     * @param lang
     */
 setLanguage(lang): void
 {
     // Set the selected language for the toolbar
     this.selectedLanguage = lang;

     // Use the selected language for translations
     this._translateService.use(lang.id);
 }


}
