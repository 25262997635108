import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {HomeModule} from '../app/modules/home/home.module';
import { AboutModule } from './modules/about/about.module';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'inicio',
    loadChildren: () => HomeModule
  },
  {
    path: 'nosotros',
    loadChildren: () => AboutModule
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
