export class OrderFakeDb{

    public static country = [
        {
            'id': '1',
            'name_country': 'Peru',
            'code_country': 0,
            'code_status': 0,
            'currency': [
                {
                    'id': '1',
                    'name_currency': 'PEN',
                    'code_currency': 0
                }
            ]
        },
        {
            'id': '2',
            'name_country': 'Brasil',
            'code_country': 1,
            'code_status': 0,
            'currency': [
                {
                    'id': '1',
                    'name_currency': 'BRL',
                    'code_currency': 1
                }
            ]
        },
        {
            'id': '3',
            'name_country': 'EEUU',
            'code_country': 2,
            'code_status': 0,
            'currency': [
                {
                    'id': '1',
                    'name_currency': 'USD',
                    'code_currency': 2
                }
            ]
        }
    ];

    public static currency = [
        {
            'id': '1',
            'name_currency': 'PEN',
            'code_country': 0,
            'code_status': 0
        },
        {
            'id': '2',
            'name_currency': 'BRL',
            'code_country': 1,
            'code_status': 0
        },
        {
            'id': '3',
            'name_currency': 'USD',
            'code_country': 2,
            'code_status': 0
        }
    ];

    public static type_shipping = [
        {
            'id': '1',
            'code_sent': 0,
            'ammount': 1.50,
            'name': 'Normal (15%)',
            'sumilla': 'Llegará máximo en 72 horas'
        },
        {
            'id': '2',
            'code_sent': 1,
            'ammount': 3.0,
            'name': 'Express (30%)',
            'sumilla': 'Llegará máximo en 24 horas'
        }
    ];

    public static bank_details= [
        {
            'id': '1',
            'exchange_rate': 3.60,
            'accounts_sent' : 
            [
                {
                    'id': 0,
                    'name': 'Jen (PEN) - 14325432432432',
                    'code_bank': 0,
                    'code_account': 0,
                    'digits': 10
                },
                {
                    'id': 1,
                    'name': 'Jen (USD) - 87535353626363',
                    'code_bank': 2,
                    'code_account': 2,
                    'digits': 12
                }
            ], 
            'accounts_received' : 
            [   
                {
                    'id': 0,
                    'name': 'Carlos (PEN) - 0987654321',
                    'code_bank': 0,
                    'code_account': 0,
                    'digits': 10
                },
                {
                    'id': 1,
                    'name': 'Juan (USD) - 99999999999',
                    'code_bank': 2,
                    'code_account': 2,
                    'digits': 12
                }
            ],

        }
    ];

    public static simulator= [
        {
            'id': '1',
            'commission': 1.50,
            'taxes': 0,
            'discounts': 0,
            'total': 98.50
        }
    ];

    public static order_number= [
        {
            'id': '1',
            'order': 'TZ01218'
        }
    ];

    public static accountsTranzfer = [
        {
            'id': '0',
            'company_name': 'Tranzfer.me',
            'person_name': 'Antonio Zucchetti',
            'type_account': 'Interbank - CTA Ahorros Dólares',
            'account_number': '10015-1155-4515-4541'
        }
    ];

    public static constancy = [
        {
            'id': '1',
            'order_code': 'TZ01218',
            'status': 'En proceso',
            'start_date': '28 de enero - 12-25 pm',
            'end_date': '28 de enero - 12:30 pm'
        }
    ];

}