export const locale = {
    lang: 'br',
    data: {
        'Login': {
            'correo': 'Email',
            'google': 'Continue com google',
            'lbl_correo': 'Digite um e-mail válido',
            'contrasena': 'Digite sua senha',
            'lbl_contrasena': 'Digite uma senha válida',
            'btn_continuar': 'Continuar',
            'btn_crear_cuenta': 'Criar conta',
            'btn_olvidar_contrasena': 'Esqueceu sua senha?',
            'btn_volver': 'Voltar'
        },
        'OlvidasteContrasena': { 
            'titulo': 'Recuperação de conta',
            'sumilla': 'Digite seu e-mail para recuperar sua conta',
            'correo': 'Email',
            'lbl_correo': 'Digite um e-mail válido',
            'sumilla_confirmacion': 'Enviamos uma mensagem para que você possa confirmar sua conta para o seguinte e-mail',
            'btn_siguiente': 'Próximo',
            'titulo_cambio_contrasena': 'Mudança de senha',
            'sumilla_cambio_contrasena': 'Digite seu e-mail para recuperar sua conta',
            'contrasena': 'Senha',
            'confirmar_contrasena': 'Confirme a senha',
            'codigo': 'Código',
            'lbl_codigo': 'Insira um código',
            'lbl_contrasena': 'Digite uma senha válida',
            'btn_continuar': 'Continuar',
            'btn_volver': 'Voltar',
            'confirmacion_correo': 'Confirmação de e-mail',
        },
        'CrearCuenta': {
            'nombre': 'Nome',
            'apellido': 'Sobrenome',
            'correo': 'Correio',
            'contrasena': 'Senha',
            'confirmar_contrasena': 'Confirme a senha',
            'terminos_condiciones_0': 'Aceito a',
            'terminos_condiciones_1': 'política de privacidade',
            'terminos_condiciones_2': 'do tranzfer.me',
            'btn_cancelar': 'Cancelar',
            'btn_registrar': 'Cadastre-se',
            'lbl_nombre': 'Por favor, insira um nome válido',
            'lbl_apellido': 'Por favor, insira um sobrenome válido',
            'lbl_correo': 'Digite um e-mail válido',
            'lbl_contrasena': 'Digite uma senha válida',
            'lbl_confirmar_contrasena': 'Digite uma senha válida',
            'confirmacion_correo': 'Confirmação de e-mail',
            'lbl_confirmacion_correo': 'Enviamos uma mensagem para que você possa confirmar sua conta para o seguinte e-mail:',
            'ingresa_codigo': 'Insira um código',
            'btn_continuar': 'Confirme',
            'reenviar_codigo': 'Reenviar código'
        },
        'IngresaMontos':{
            'titulo': 'Insira os valores',
            'envias': 'Você envia de',
            'recibes': 'Seu destinatário recebe em',
            'pais': 'Country',
            'monto': 'Quantia',
            'divisa': 'Moeda',
            'btn_aplicar': 'Aplicar',
            'costos': 'Custos',
            'inp_cupon': 'Digite aqui',
            'comision': 'Comissão de envio',
            'impuestos': 'Impostos',
            'descuentos':'Descontos',
            'otros_cargos': 'Outros cargos',
            'sub_total': 'Sub total',
            'total_convertir': 'Total para convert',
            'tipo_cambio': 'Taxa de câmbio',
            'informativo': 'Para concluir seu pedido você deve inserir o número de suas contas.',
            'enviar_dinero': 'De qual conta você enviará seu dinheiro?',
            'recibir_dinero': 'Em qual conta o seu dinheiro será recebido?',
            'descripcion': 'Descrição (opcional)',
            'btn_orden': 'Registrar pedido',
            'btn_cancelar': 'Cancelar',
            'agregar_cuenta': 'Adicionar nova conta de envio',
            'agregar_cuenta_recibir_dinero': 'Adicione uma conta para receber dinheiro',
            'tipo_envio':'Escolha o seu tipo de envio',
            'codigo_promocional': 'Você tem um código promocional?',
            'datos_bancarios': 'Insira os dados bancários',
            'cuenta_enviar': 'Selecione uma conta para enviar o dinheiro',
            'cuenta_recive':'Selecione uma conta que receberá seu dinheiro',
            'titulo_dinero': 'Enviar dinheiro'
        },
        'SubeConstancia':{
            'orden': 'O número do seu pedido é:',
            'sumilla_1': 'Agora você só tem que transferir',
            'sumilla_2': 'Para a seguinte conta Tranzfer.me:',
            'copiar': 'Cópia',
            'transferencia': 'Você já fez a transferência?',
            'sube_voucher': 'Faça upload do seu cupom aqui',
            'btn_subir': 'Upload',
            'informativo': 'Para concluir o seu pedido, você deve tirar uma captura de tela da sua prova',
            'tipo_cambio': 'Taxa de câmbio',
            'tipo_envio': 'Tipo de envio',
            'destinatario': 'Seu destinatário receberá:',
            'btn_continuar': 'Continuar',
            'btn_editar': 'Editar',
            'sube_constancia': 'Envie sua constância'
        },
        'ResumenOrden': {
            'orden': 'Status do pedido',
            'fecha_estimada': 'Data estimada de chegada',
            'resumen': 'Resumo',
            'envias': 'Frete',
            'recibes': 'Você recebe',
            'costos': 'Custos',
            'comision': 'Comissão de envio',
            'impuestos': 'Impostos',
            'descuentos': 'Descontos',
            'total_convertir': 'Total para convert',
            'tipo_cambio': 'Taxa de câmbio',
            'detalles_cuenta': 'Detalhes da conta',
            'cuenta_origen': 'Conta de origem',
            'cuenta_destino': 'Conta de destino',
            'btn_ordenes': 'Vá para meus pedidos',
            'resumen_orden': 'Resumo do pedido',
            'estado_sumilla': 'Estamos validando os dados e o comprovante de transferência',
            'descripcion_orden': 'Descrição do pedido'
        },
        'AgregarCuenta':{
            'banco': 'Banco',
            'pais': 'Country',
            'correo': 'Correio',
            'lbl_correo': 'Digite um e-mail válido',
            'tipo_cuenta': 'Tipo de conta',
            'monedas': 'Moeda',
            'numero_cuenta': 'Número da conta',
            'cci': 'CCI',
            'nombres': 'Nomes',
            'apellidos': 'Sobrenome',
            'alias': 'Aliases',
            'lbl_numero_cuenta': 'Insira um número de conta',
            'lbl_cci': 'Digite o CCI',
            'lbl_nombres': 'Digite seu nome',
            'lbl_apellidos': 'Digite seu sobrenome',
            'lbl_aliias': 'Alias',
            'check_cuenta': 'É minha conta pessoal',
            'check_operaciones': 'Economize para minhas próximas operações',
            'btn_cancelar': 'Cancelar',
            'btn_cuenta': 'Adicionar conta',
            'cuenta_origen': 'Adicionar conta de origem'
        },
        'Ordenes':{
            'tab_creadas': 'Criado',
            'tab_proceso': 'Em processo',
            'tab_finalizadas': 'Concluído',
            'btn_enviar': 'Envie dinheiro',
            'envias': 'Você envia de',
            'recibes': 'Seu destinatário recebe em',
            'numero_orden': 'Pedido nº',
            'orden_registrada': 'Pedido registrado',
            'orden_enviado': 'Enviado',
            'orden_finalizado': 'Terminado',
            'constancia': 'Veja o registro',
            'cancelar_orden': 'Cancelar pedido',
            'tipo_envio': 'Tipo de envio',
            'tipo_cambio': 'Taxa de câmbio',
            'cuenta_origen': 'Conta de origem',
            'cuenta_destino': 'Conta de destino',
            'fecha_estimada': 'Data estimada de chegada',
            'modal_titulo': 'Ordem',
            'modal_sumilla': 'Tem certeza de que deseja excluir o registro?',
            'modal_confirmar': 'Confirme',
            'modal_cancelar': 'Cancelar',
            'ordenes': 'Minhas ordens'
        },
        'CuentasBancarias':{
            'titulo': 'Contas bancárias',
            'tab_origen': 'Origem',
            'tab_destino': 'Destino',
            'btn_cuenta': 'Adicionar conta',
            'cancelar_orden': 'Cancelar pedido',
            'nombre': 'Nome',
            'cci': 'CCI',
            'tipo_cuenta': 'Tipo de conta',
            'tipo_moneda': 'Tipo de moeda'
        },
        'Perfil': {
            'tab_cuenta': 'Dados da conta',
            'tab_contrasena':'Senha',
            'titulo': 'olá',
            'datos_cuenta': 'Dados da conta',
            'nombres_apellidos': 'Nomes e sobrenomes',
            'correo': 'Email',
            'datos_personales': 'Dados pessoais',
            'fecha_nacimiento': 'Data de nascimento',
            'pais': 'Country',
            'genero': 'Gênero',
            'celular': 'Celular',
            'btn_agregar': 'Adicionar',
            'contrasena': 'Alterar senha',
            'contrasena_actual': 'senha atual',
            'contrasena_nueva': 'Nova senha',
            'confirmar_contrasena': 'Confirme a nova senha',
            'btn_contrasena': 'Alterar senha',
            'btn_cancelar': 'Cancelar',
            'perfil': 'Meu perfil',
            'lbl_nombres_apellidos': 'Insira o nome e o sobrenome',
            'btn_guardar': 'Manter',
            'lbl_pais': 'Selecione um pais',
            'lbl_opcion': 'Por favor selecione uma opção',
            'lbl_celular': 'Digite um número de telefone celular'
        },
        'CentroAyuda':{
            'titulo': 'olá',
            'tab_enviar': 'Envie dinheiro',
            'tab_seguridad': 'Segurança',
            'panel_1': {
                'panel': 'O que é a Tranzfer.me?',
                'text':  'Somos um Startup que administra transferências de dinheiro para o exterior. Trabalhamos com base em uma economia colaborativa formada por uma rede de pessoas como você, que nos permite reduzir os custos das transferências internacionais de dinheiro.'    
            },
            'panel_2': {
                'panel': 'O que preciso de transferir?',
                'text': 'Tanto você e seu beneficiário devem ter uma conta bancária. Se você ou seu beneficiário estiver localizado nos Estados Unidos, é recomendável que você tenha uma conta Zelle ativa.'    
            },
            'panel_3': {
                'panel': 'Quais são as etapas?',
                'text':  '1. O remetente deve digitar a quantidade a ser enviada em www.tranzfer.me.<br>' +
                 '2. Indicar as moedas (do Peru, EUA ou Brasil) a serem enviadas e recebidas. A calculadora indicará a taxa de câmbio equivalente que o destinatário receberá.' + 
                 '<br> 3. Clique em "Enviar dinheiro" e você será redirecionado para uma comunicação direta pelo WhatsApp.' +
                 '<br> 4. Completar as etapas finais necessárias para que a transferência seja efetiva.'
            },
            'panel_4': {
                'panel': 'Que tipo de comissões ou método de envio tem?',
                'text':  'TTemos dois métodos de envio, dependendo de nosso limite diário: <br>' +
                 '- Envio normal a uma taxa de 1,50% do valor. <br>' +
                 '- Envio Premium a uma taxa de 3,00% do valor.<br>' +
                 'Enquanto que para quantidades superiores ao limite diário:<br>' +
                 '- Envio normal a uma taxa de 5,00% do valor. <br>'+
                 '- Envio Premium a uma taxa de 7,00% do valor.'
            },
            'panel_5': {
                'panel': 'Em que países opera?',
                'text':  'Trabalhamos com o Peru, o Brasil e os Estados Unidos. Proximamente com mais países da América Latina.'    
            },
            'panel_6': {
                'panel': 'Qual é a taxa de câmbio que utiliza?',
                'text':  'Escolhemos a taxa de câmbio mais barata, com base em diferentes casas de câmbio digitais.'    
            },
            'panel_7': {
                'panel': 'Quanto tempo leva para o meu dinheiro chegar?',
                'text':  'O dinheiro chega ao destino dentro de 24 horas (envio premium) a 72 horas (envio normal), no máximo. O prazo é efetivo a partir do momento em que o dinheiro entra na conta bancária proporcionada pela Tranzfer.me. O tempo dependerá muito do tempo e do banco com o qual a transação for feita.'    
            },
            'panel_8': {
                'panel': 'Posso fazer um depósito na minha conta? ',
                'text':  'Após avaliação e comprovação de identidade.'    
            },
            'panel_9': {
                'panel': 'Quais são os seus horários de trabalho?',
                'text':  'Nossos assessores o atenderão ativamente durante as horas seguintes: Segunda a sexta-feira das 9:00 às 21:00 horas, aos sábados das 10:00 às 13:00 horas. No entanto, você pode fazer seu pedido a qualquer momento.'    
            },
            'panel_10': {
                'panel': 'Preciso de alguém da equipa da Tranzfer.me para me ajudar?',
                'text':  'Se você precisar de orientação, não duvide em escrever para nosso e-mail tranzfers@tranzfer.me ou por qualquer de nossas redes sociais.'    
            },
            'panel_11': {
                'panel': 'Qual é a garantia de que o dinheiro chegará ao seu destino?',
                'text':  'Temos políticas de devolução em caso de qualquer contratempo com o processo de envio ou se solicitado pelo remetente. A Tranzfer.me assegura o sucesso do envio desde que os dados solicitados sejam verdadeiros e validados. Até à data, temos uma taxa de sucesso de 100% nos envios.'
            },
            'panel_12': {
                'panel': 'É seguro?',
                'text':  'Somos uma empresa legalmente constituída (RUC 20603997922). Temos procedimentos de segurança, tratamento e encriptação de dados pessoais. Pode contactar-nos através de qualquer um dos nossos canais de comunicação.'    
            },
            'informacion': 'Se precisar de ajuda personalizada, não hesite em nos contatar por estes meios',
            'centro_ayuda': 'Centro de ajuda'
        },
        'ValidarUsuario' : {
            'titulo': 'Queremos validar se você não é um robô, resolva a seguinte operação matemática'
        }
    }
};
