import {throwError as observableThrowError, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';

@Injectable()
export class HttpInterceptorImplement implements HttpInterceptor {
  
  constructor(private http: HttpClient,
              private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.setItem("token", 'sss');
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Allowed', 'true')
      .append('Authorization', `bearer ${token}`);
    const reqCloned = req.clone({
      headers: headers
    });

    return next.handle(reqCloned).pipe(catchError((err) => {
        if(err.status = 401){
          console.log("token expiro");
        }
      return observableThrowError(err);
    })) as any;
  }
}
