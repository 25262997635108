import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { throwError } from 'rxjs';


@Component({
  selector: 'app-allied',
  templateUrl: './allied.component.html',
  styleUrls: ['./allied.component.scss']
})
export class AlliedComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: true,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    nav: false,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 4,
      }
    }
  };

  imgArray = [
    {
      id: 1,
      url: '/assets/images/aliados/aiesec.png'
    },
    {
      id: 2,
      url: '/assets/images/aliados/asesori.png'
    },
    {
      id: 3,
      url: '/assets/images/aliados/utec-ventures.png'
    },
    {
      id: 4,
      url: '/assets/images/aliados/voz-animal.png'
    },
    {
      id: 5,
      url: '/assets/images/aliados/work-travel.png'
    }
  ]
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }



}
