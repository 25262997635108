import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import {SharedModule} from '../../shared/shared.module';
import {MatInputModule} from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CalculatorComponent } from 'src/app/modules/home/componets/calculator/calculator.component';
import { AlliedComponent } from './componets/allied/allied.component';
import { OrderService } from 'src/app/service/order.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CarouselModule } from 'ngx-owl-carousel-o';
const routes = [
  {
    path: '',
    component: CalculatorComponent
  }
]


@NgModule({
  declarations: [
    CalculatorComponent,
    AlliedComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    SharedModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    MatMenuModule,
    MatToolbarModule,
    MatSidenavModule,
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatDividerModule,
    MatCardModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CarouselModule
  ],
  providers: [
    OrderService
  ],
  exports: [RouterModule]
})
export class HomeModule { }
