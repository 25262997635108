export const locale = {
    lang: 'es',
    data: {
        'Login': {
            'correo': 'Correo electrónico',
            'google': 'Continuar con google',
            'lbl_correo': 'Ingresa un correo electrónico valido',
            'contrasena': 'Ingresa tu contraseña',
            'lbl_contrasena': 'Ingresa una contraseña valida',
            'btn_continuar': 'Continuar',
            'btn_crear_cuenta': 'Crear cuenta',
            'btn_olvidar_contrasena': '¿Olvidaste tu contraseña?',
            'btn_volver': 'Atras'
        },
        'OlvidasteContrasena': { 
            'titulo': 'Recuperación de la cuenta',
            'sumilla': 'Ingresa su correo electrónico para recuperar su cuenta',
            'correo': 'Correo electrónico',
            'lbl_correo': 'Ingresa un correo electrónico valido',
            'sumilla_confirmacion': 'Te hemos enviado un mensaje para que puedas confirmar tu cuenta al siguiente correo electrónico:',
            'btn_siguiente': 'Siguiente',
            'titulo_cambio_contrasena': 'Cambio de contraseña',
            'sumilla_cambio_contrasena': 'Ingresa su correo electrónico para recuperar su cuenta',
            'contrasena': 'Contraseña',
            'confirmar_contrasena': 'Confirmar contraseña',
            'codigo': 'Código',
            'lbl_codigo': ' Ingresa un código',
            'lbl_contrasena': 'Ingresa una contraseña valida',
            'btn_continuar': 'Continuar',
            'btn_volver': 'Atras',
            'confirmacion_correo': 'Confirmación de correo',
        },
        'CrearCuenta': {
            'nombre': 'Nombre',
            'apellido': 'Apellido',
            'correo': 'Correo',
            'contrasena': 'Contraseña',
            'confirmar_contrasena': 'Confirmar contraseña',
            'terminos_condiciones_0': 'Acepto la',
            'terminos_condiciones_1': 'política de privacidad',
            'terminos_condiciones_2': 'de tranzfer-me',
            'btn_cancelar': 'Cancelar',
            'btn_registrar': 'Registrar',
            'lbl_nombre': 'Ingresa un nombre valido',
            'lbl_apellido': 'Ingresa un apellido valido',
            'lbl_correo': 'Ingresa un correo valido',
            'lbl_contrasena': 'Ingresa una contraseña valida',
            'lbl_confirmar_contrasena': 'Ingresa una contraseña valida',
            'confirmacion_correo': 'Confirmación de correo',
            'lbl_confirmacion_correo': 'Te hemos enviado un mensaje para que puedas confirmar tu cuenta al siguiente correo electrónico:',
            'ingresa_codigo': 'Ingresa un código',
            'btn_continuar': 'Confirmar',
            'reenviar_codigo': 'Reenviar código'
        },
        'IngresaMontos':{
            'titulo': 'Ingresa los montos',
            'envias': 'Tu envías desde',
            'recibes': 'Tu destinatario recibe en',
            'pais': 'País',
            'monto': 'Monto',
            'divisa': 'Divisa',
            'btn_aplicar': 'Aplicar',
            'costos': 'Costos', 
            'inp_cupon': 'Ingresalo aquí',
            'comision': 'Comisión de envío',
            'impuestos': 'Impuestos',
            'descuentos':'Descuentos',
            'otros_cargos': 'Otros cargos',
            'sub_total': 'Sub total',
            'total_convertir': 'Total a convertir:',
            'tipo_cambio': 'Tipo de cambio',
            'informativo': 'Para completar tu orden debes ingresar el número de tus cuentas.',
            'enviar_dinero': '¿Desde que cuenta enviarás tu dinero?',
            'recibir_dinero': '¿En qué cuenta se recibirá tu dinero?',
            'descripcion': 'Descripción (opcional)',
            'btn_orden': 'Registrar orden',
            'btn_cancelar': 'Cancelar',
            'agregar_cuenta': 'Agregar nueva cuenta de envío',
            'agregar_cuenta_recibir_dinero': 'Agregar cuenta para recibir dinero',
            'tipo_envio':'Elige tu tipo de envío',
            'codigo_promocional': '¿Tienes un código promocional?',
            'datos_bancarios': 'Ingresa los datos bancarios',
            'cuenta_enviar': 'Selecciona una cuenta para enviar el dinero',
            'cuenta_recive':'Selecciona una cuenta que recibirá tu dinero',
            'titulo_dinero': 'Enviar dinero'
        },
        'SubeConstancia':{
            'orden': 'Tu número de orden es:',
            'sumilla_1': 'Ahora solo tienes que transferir',
            'sumilla_2': 'A la siguiente cuenta de Tranzfer.me:',
            'copiar': 'Copiar',
            'transferencia': '¿Ya realizaste la transferencia?',
            'sube_voucher': 'Sube tu boucher aquí',
            'btn_subir': 'Subir',
            'informativo': 'Para completar tu orden debes tomar captura de pantalla de tu constancia.',
            'tipo_cambio': 'Tipo de cambio:',
            'tipo_envio': 'Tipo de envío:',
            'destinatario': 'Tu destinatario recibirá:',
            'btn_continuar': 'Continuar',
            'btn_editar': 'Editar',
            'sube_constancia': 'Sube tu constancia'
        },
        'ResumenOrden': {
            'orden': 'Estado de la orden',
            'fecha_estimada': 'Fecha estimada de llegada',
            'resumen': 'Resumen',
            'envias': 'Envías',
            'recibes': 'Recibes',
            'costos': 'Costos',
            'comision': 'Comisión de envío',
            'impuestos': 'Impuestos',
            'descuentos': 'Descuentos',
            'total_convertir': 'Total a convertir',
            'tipo_cambio': 'Tipo de cambio',
            'detalles_cuenta': 'Detalles de las cuentas',
            'cuenta_origen': 'Cuenta de origne',
            'cuenta_destino': 'Cuenta de destino',
            'btn_ordenes': 'Ir a mis órdenes',
            'resumen_orden': 'Resumen de orden',
            'estado_sumilla': 'Estamos validando los datos y la constancia de transferencia.',
            'descripcion_orden': 'Descripción de la orden'
        },
        'AgregarCuenta':{
            'pais': 'País',
            'correo': 'Correo',
            'lbl_correo': 'Ingresa un correo valido',
            'banco': 'Banco',
            'tipo_cuenta': 'Tipo de cuenta',
            'monedas': 'Moneda',
            'numero_cuenta': 'Número de cuenta',
            'cci': 'CCI',
            'nombres': 'Nombres',
            'apellidos': 'Apellidos',
            'alias': 'Alias',
            'lbl_numero_cuenta': ' Ingresa un número de cuenta',
            'lbl_cci': ' Ingresa el CCI',
            'lbl_nombres': 'Ingresa tu nombre',
            'lbl_apellidos': 'Ingresa tu apellido',
            'lbl_aliias': 'Alías',
            'check_cuenta': 'Es mi cuenta personal',
            'check_operaciones': 'Guardar para mis siguiente operaciones',
            'btn_cancelar': 'Cancelar',
            'btn_cuenta': 'Agregar cuenta',
            'cuenta_origen': 'Agregar cuenta de origen'
        },
        'Ordenes':{
            'tab_creadas': 'Creadas',
            'tab_proceso': 'En proceso',
            'tab_finalizadas': 'Finalizadas',
            'btn_enviar': 'Enviar dinero',
            'envias': 'Tu envías desde',
            'recibes': 'Tu destinatario recibe en',
            'numero_orden': 'N° orden',
            'orden_registrada': 'Orden registrada',
            'orden_enviado': 'Enviado',
            'orden_finalizado': 'Finalizado',
            'constancia': 'Ver constancia',
            'cancelar_orden': 'Cancelar orden',
            'tipo_envio': 'Tipo de envío',
            'tipo_cambio': 'Tipo de cambio',
            'cuenta_origen': 'Cuenta origen',
            'cuenta_destino': 'Cuenta destino',
            'fecha_estimada': 'Fecha estimada de llegada',
            'modal_titulo': 'Orden',
            'modal_sumilla': '¿Estas seguro de eliminar la constancia?',
            'modal_confirmar': 'Confirmar',
            'modal_cancelar': 'Cancelar',
            'ordenes': 'Mis órdenes'
        },
        'CuentasBancarias':{
            'titulo': 'Cuentas Bancarias',
            'tab_origen': 'Origen',
            'tab_destino': 'Destino',
            'btn_cuenta': 'Agregar cuenta',
            'cancelar_orden': 'Cancelar orden',
            'nombre': 'Nombre',
            'cci': 'CCI',
            'tipo_cuenta': 'Tipo de cuenta',
            'tipo_moneda': 'Tipo de moneada'
        },
        'Perfil': {
            'tab_cuenta': 'Datos de cuenta',
            'tab_contrasena':'Contraseña',
            'titulo': '¡Hola',
            'datos_cuenta': 'Datos de cuenta',
            'nombres_apellidos': 'Nombres y apellidos',
            'correo': 'Correo electrónico',
            'datos_personales': 'Datos personales',
            'fecha_nacimiento': 'Fecha de nacimiento',
            'pais': 'País',
            'genero': 'Genero',
            'celular': 'Celular',
            'btn_agregar': 'Agregar',
            'contrasena': 'Cambiar contraseña',
            'contrasena_actual': 'Contraseña actual',
            'contrasena_nueva': 'Nueva contraseña',
            'confirmar_contrasena': 'Confirmar nueva contraseña',
            'btn_contrasena': 'Cambiar contraseña',
            'btn_cancelar': 'Cancelar',
            'perfil': 'Mi perfil',
            'lbl_nombres_apellidos': 'Ingresa nombres y apellidos',
            'btn_guardar': 'Guardar',
            'lbl_pais': 'Selecciona un país',
            'lbl_opcion': 'Selecciona una opción',
            'lbl_celular': 'Ingresa un número de celular'
        },
        'CentroAyuda':{
            'titulo': '¡Hola',
            'tab_enviar': 'Enviar dinero',
            'tab_seguridad': 'Seguridad',
            'panel_1': {
                'panel': '¿Qué es Tranzfer.me?',
                'text':  'Somos una Startup que gestiona envíos de dinero al exterior. Trabajamos en base a una economía colaborativa conformada por una red de personas como tu que nos permite reducir los costos de las transferencias internacionales de dinero.'    
            },
            'panel_2': {
                'panel': '¿Qué necesito para transferir?',
                'text': 'Tanto tú como tu beneficiario deben contar con una cuenta bancaria. En el caso que tú o tu beneficiario se encuentren en Estados Unidos, adicionalmente se recomienda que tengan una cuenta de Zelle activa.'    
            },
            'panel_3': {
                'panel': '¿Cuáles son los pasos?',
                'text':  '1. El remitente deberá ingresar el monto a enviar en www.tranzfer.me <br> ' + 
                 '2. Indicar divisas (de Perú, EEUU o Brasil) a enviar y recibir. La calculadora indicará el tipo de cambio equivalente que recibirá el destinatario. ' + 
                 '<br> 3. Clic en “Enviar dinero” y se derivará a una comunicación directa con nosotros por WhatsApp.' +
                 '<br> 4. Completar los pasos finales requeridos para hacer efectiva la transferencia.'
            },
            'panel_4': {
                'panel': '¿Que tipo de comisión o modalidad de envío tienen?',
                'text':  'Tenemos dos métodos de envío según nuestro límite diario: <br>' +
                 '- El envío normal a una tasa del 1.50% del monto.<br>' +
                 '- El envío premium a una tasa del 3.00% del monto.<br>' +
                 'Mientras que para montos mayores al límite diario:<br>' +
                 '- El envío normal a una tasa del 5.00% del monto.<br>'+
                 '- El envío premium a una tasa del 7.00% del monto.'
            },
            'panel_5': {
                'panel': '¿En que países operan?',
                'text':  'Operamos con Perú, Brasil y Estados Unidos. Próximamente con más países de latinoamerica.'    
            },
            'panel_6': {
                'panel': '¿En qué tipo de cambio se basan?',
                'text':  'Elegimos el tipo de cambio más económico,  basados en casas de cambio digitales.'    
            },
            'panel_7': {
                'panel': '¿En cuanto tiempo llega mi dinero?',
                'text':  'El dinero llega al destino en un plazo de 24 horas (envío premium) a 72 horas (envío normal) como máximo. El plazo se hace efectivo desde el momento en que el dinero ingresa a la cuenta bancaria proporcionada por Tranzfer.me. El tiempo va a depender mucho del horario y banco con el que se haga la transacción.'    
            },
            'panel_8': {
                'panel': '¿Puedo hacer depósito en cuenta?',
                'text':  'Previa evaluación y verificación de identidad.'    
            },
            'panel_9': {
                'panel': '¿Horario de atención?',
                'text':  'Nuestros asesores te atenderán activamente en los siguientes horarios: Lunes a Viernes de 9 am a 9pm, los Sábados de 10 am - 1 pm. Aun así, puedes hacer tu requerimiento en cualquier momento.'    
            },
            'panel_10': {
                'panel': '¿Quiero que alguien del equipo de Tranzfer.me me ayude?',
                'text':  'Si necesitas asesoramiento, no dudes en escribirnos a nuestro correo  tranzfers@tranzfer.me o por cualquiera de nuestras redes sociales.'    
            },
            'panel_11': {
                'panel': '¿Cuál es la garantía que el dinero llegue al destino? ',
                'text':  'Contamos con políticas de devolución de haber algún contratiempo con el proceso de envío o de ser solicitado por el remitente. Tranzfer.me asegura el éxito del envío siempre y cuando los datos solicitados sean verídicos y validados. Al día de hoy tenemos un 100% de éxito en los envíos.'
            },
            'panel_12': {
                'panel': '¿Es seguro? ',
                'text':  'Somos una empresa legalmente constituida (RUC 20603997922). Contamos con procedimientos de seguridad, manejo y encriptación de datos personales. Puedes contactarte con nosotros por cualquiera de nuestros canales de comunicación. '    
            },
            'informacion': 'Si necesitas ayuda personalizada, no dudes en contactarte con nosotros por estos medios',
            'centro_ayuda': 'Centro de ayuda'
        },
        'ValidarUsuario' : {
            'titulo': 'Queremos validar que no seas un robot, por favor resuelve la siguiente operación matemática'
        }
    }
};
