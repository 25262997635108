
<div  fxlayout="column" id="register-account">
    
    
    <form  class="register-form mt-md-40 mt-20" name="accountForm" 
    [formGroup]="accountForm" (ngSubmit)="submitRegister(accountForm.value)" novalidate
     fxLayout="column" fxLayoutAlign="center center">
        <div class="btn-next" (click)="changeNavigation(0)">
            <span class="material-icons">arrow_forward_ios</span>
        </div>
        <img src="assets/images/logos/logo_tranzfer-me.png" class="img-tranzfer"/>
        <div class="border-outline">
            <h2>{{'IngresaMontos.envias' | translate}} <strong>{{nameCountry}}</strong></h2>
            <div class="card-send" fxLayout="row" fxlayoutalign="center center">
                    <div class="card-send-item">
                        <h2>{{'IngresaMontos.pais' | translate}}</h2>
                        <mat-form-field appearance="outline">
                            <mat-select [(value)]="selectFromCountry" formControlName="fromCountry" 
                            (selectionChange)="fromCountrySelect($event.value)">
                                <mat-select-trigger>
                                    <div class="icon-size">
                                        <span class="flag-icon-{{selectFromCountry}}"> <span class="icon-text">{{nameCountry }}</span> </span> 
                                    </div>
                                </mat-select-trigger>
                                <mat-option *ngFor="let country of countries" 
                                    [value]="country.code_country">
                                    <div class="icon-size"> 
                                        <span class="flag-icon-{{country.code_country}}"> <span  class="icon-text" >{{country.name_country }}</span></span>
                                    </div>  
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="card-send-item">
                        <h2>{{'IngresaMontos.monto' | translate}}</h2>
                        <mat-form-field appearance="outline">
                            <input matInput class="text-center"
                            #txtVal (keyup)="changeFromAmmount(txtVal.value)" 
                            formControlName="fromAmmount">
                        </mat-form-field>
                        
                    </div>
                    <div class="card-send-item">
                        <h2>{{'IngresaMontos.divisa' | translate}}</h2>
                        <mat-form-field appearance="outline">
                            <mat-select [(ngModel)]="selectFromDivisa" [(value)]="selectFromDivisa" formControlName="fromBadge">
                                <mat-select-trigger>
                                    <div class="icon-divisa">
                                        <span class="icon-text">{{selectFromDivisa}}</span>
                                    </div>
                                </mat-select-trigger>
                                <mat-option *ngFor="let divisas of divisaFrom" [value]="divisas.name_currency">
                                    <div class="icon-divisa">
                                        <span class="icon-text">{{divisas.name_currency}}</span>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
            </div>
        </div>
        <div class="border-outline">
            <h2>{{'IngresaMontos.recibes' | translate}} <strong>{{nametoCountry}}</strong></h2>
            <div class="card-send" fxLayout="row" fxlayoutalign="center center">
                <div class="card-send-item">
                    <h2>{{'IngresaMontos.pais' | translate}}</h2>
                    <mat-form-field appearance="outline">
                        <mat-select [(value)]="selectToCountry" formControlName="toCountry" (selectionChange)="toCountrySelect($event.value)">
                        <mat-select-trigger>
                            <div class="icon-size">
                                <span class="flag-icon-{{selectToCountry}}"> <span class="icon-text">{{nametoCountry }}</span> </span> 
                            </div>
                        </mat-select-trigger>
                          <mat-option *ngFor="let country of countries" 
                             [value]="country.code_country">
                             <div class="icon-size">
                                <span class="flag-icon-{{country.code_country}}"> <span class="icon-text">{{country.name_country }}</span></span>
                             </div>
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="card-send-item">
                    <h2>{{'IngresaMontos.monto' | translate}}</h2>
                    <mat-form-field appearance="outline">
                        <input matInput #txtAmmount (keyup)="changetoAmmount(txtAmmount.value)" 
                         formControlName="toAmmount">
                    </mat-form-field>
                </div>
                <div class="card-send-item">
                    <h2>{{'IngresaMontos.divisa' | translate}}</h2>
                    <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="selectToDivisa" [(value)]="selectToDivisa" formControlName="toBadge">
                            <mat-select-trigger>
                                <div class="icon-divisa">
                                    <span class="icon-text">{{selectToDivisa }}</span>
                                </div>
                            </mat-select-trigger>
                          <mat-option *ngFor="let divisas of divisaTo" [value]="divisas.name_currency">
                            <div class="icon-divisa">
                                <span class="icon-text">{{divisas.name_currency}}</span>
                            </div>
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="card-outline"  >
            <h2 >{{'IngresaMontos.tipo_envio' | translate}}</h2>
            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-card class="mr-5" (click)="changeStep(0)" [ngClass]="{'active': codShipping !== 1}">
                    <mat-card-content class="text-center">
                        <h3 class="mb-0">{{typeShipping[0]['name']}}</h3>
                        <p class="mt-0">{{typeShipping[0]['sumilla']}}</p>
                    </mat-card-content>
                </mat-card>
                <mat-card (click)="changeStep(1)" [ngClass]="{'active': codShipping !== 0}">
                    <mat-card-content class="text-center">
                        <h3 class="mb-0">{{typeShipping[1]['name']}}</h3>
                        <p class="mt-0">{{typeShipping[1]['sumilla']}}</p>
                    </mat-card-content>
                </mat-card>
            </div>
            
        </div>
        <div class="account-detail">
            <div class="card">
                <h2>{{'IngresaMontos.costos' | translate}}</h2>
                <div class="card-content">
                    <div class="item">
                        <span> {{'IngresaMontos.comision' | translate}} </span>
                        <span class="f-right">
                         {{selectFromDivisa}} {{commission}}
                        </span>
                    </div>
                    <div class="item">
                        <span> {{'IngresaMontos.impuestos' | translate}} </span>
                        <span class="f-right">
                            {{selectFromDivisa}}  {{taxes}}
                        </span>
                    </div>
                    <div class="item">
                        <span> {{'IngresaMontos.descuentos' | translate}} </span>
                        <span class="f-right">
                            {{selectFromDivisa}}  {{discounts}}
                        </span>
                    </div>
                    <div class="item">
                        <span><strong>{{'IngresaMontos.total_convertir' | translate}}:</strong> </span>
                        <span class="f-right">
                          <strong>{{selectFromDivisa}} {{total}}</strong>
                        </span>
                    </div>
                </div>
            </div>
            <span class="h3">{{'IngresaMontos.tipo_cambio' | translate}}</span>
            <span class="p"><strong>{{exchangeRate | currency:'USD '}}</strong></span>
        </div>
        <div class="buttons" fxLayout="column" fxLayout.xs="column"
        fxLayoutAlign="space-between center">
            <button  mat-raised-button  class="btn__small btn__primary" [disabled]="accountForm.invalid">
                {{'IngresaMontos.titulo_dinero' | translate}}
            </button>
            <a href="#" class="lnk-montos">
                ¿Deseas enviar montos más grandes? <span class="txt-decoration">Entra aquí</span> 
            </a>
        </div>

        <ng-container>
            <div class="navigation-next" fxShow="{{showNext}}" fxHide="{{hideItem}}">
                <div class="item-next" *ngFor="let item of questionsArray">
                    <div class="nav-{{item.id}}" (click)="changeNavigation(item.id)">
                        <h3 fxHide="{{idItem === item.id ? true : false}}">{{item.title}}</h3>
                        <div class="sub-next" class="nav-{{item.id}}" fxHide="{{idItem === item.id ? false : true}}">
                            <h3>{{item.title}}</h3>
                            <p>{{item.description}}</p> 
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>

    <div class="type-change"  fxLayout="row wrap" fxLayoutGap.xs="32px" fxLayoutAlign.xs="flex-start">
        <div class="item-title">
            <h3><span class="color-primary">*</span>Tipo de <br> cambio</h3>
        </div>
        <div class="br-change"></div>
        <div class="item-change" fxFlex.xs="0 1 calc(10.3% - 32px)"
         fxLayout.xs="column"  [ngClass]="{'item-change': item.id !== 7, 'item-sunday': item.id === 7}" *ngFor="let item of typeChange">
            <h4>{{item.title}}</h4>
            <p>{{item.change}}<span class="material-icons">{{item.img}}</span></p>
        </div>
    </div>

    <app-allied></app-allied>
</div>
