<div class="navigation-container" >
  <mat-toolbar color="primary" class="navigation-toolbar" fxHide fxShow.xs>
      <img src="assets/images/logos/tranzfer-me.png" class="img-logo" />
      <span class="spacer"></span>
      <button fxShow.gt-xs class="btn__basic btn__xsmall" [matMenuTriggerFor]="languageMenu">
          <div fxLayout="row" fxLayoutAlign="center center">
              <img src="assets/images/iconos/globe.png" alt="Icono">
              <span class="material-icons">expand_more</span>
          </div>
      </button>
      <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
  </mat-toolbar>

  <mat-toolbar class="p-0 bg-toolbar"   fxShow fxHide.xs>
    <mat-toolbar-row >
        <ul class="ul-desktop-left">
            <li>
                <a href="" class="btn__xsmall">INICIO</a>
            </li>
            <li>
                <a href="" class="btn__xsmall">NOSOTROS</a>
            </li>
        </ul>
        <span class="spacer"></span>
        <ul class="ul-desktop-right">
            <li>
                <a href="" class="btn__xsmall">INICIAR SESIÓN</a>
            </li>
            <li>
                <a href="" class="btn__xsmall">REGÍSTRATE</a>
            </li>
            <li>
                <button fxShow.gt-xs class="btn__basic btn__xsmall" [matMenuTriggerFor]="languageMenu">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img src="assets/images/iconos/globe.png" alt="Icono">
                        <span class="material-icons">expand_more</span>
                    </div>
                </button>
                <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
                    <button class="btn__size" mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                        <span class="menu-item" fxLayout="row" fxLayoutAlign="start center">
                            <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                            <span class="iso">{{lang.title}}</span>
                        </span>
                    </button>
                </mat-menu>
            </li>
        </ul>
    </mat-toolbar-row>
  </mat-toolbar> 
  
  <mat-sidenav-container class="navigation-sidenav-container" style.marginTop.px="56">
      <mat-sidenav #snav mode="over" fixedTopGap="56">
          <div class="nav-mobile">
            <a  routerLink="." >Inicio</a>
            <a  routerLink="." >Nosotros</a>
            <a  routerLink="." >Iniciar sesión</a>
            <a  routerLink="." >Regístrate</a>
          </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
  </mat-sidenav-container>
</div>