<section>
  <div class="wrapper">
    <owl-carousel-o [options]="customOptions"  #owlCl>
      <ng-container *ngFor="let slide of imgArray" >
        
  
        <ng-template class="slide" carouselSlide  [id]="slide.id">
          <div class="img-allied">
            <img [src]="slide.url"  [alt]="slide.id" [title]="slide.id">
            <p>Aliado Estratégico</p>
          </div>
        </ng-template>
      </ng-container>
  
    </owl-carousel-o>
    <div class='nav-btn prev-slide' (click)="owlCl.prev()"></div>
    <div class='nav-btn next-slide' (click)="owlCl.next()"></div>
  </div>
</section>
