import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class OrderService {
  
  cudOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'})};
  private url = 'api/';
  constructor(
    private httpClient: HttpClient) { }



    getCountry(): Observable<any>{
      return this.httpClient.get(this.url + 'orderCountry');
    }

    getCurrency(): Observable<any>{
      return this.httpClient.get(this.url + 'orderCurrency');
    }

    getTypeShipping(): Observable<any>{
      return this.httpClient.get(this.url + 'orderTypeShipping');
    }

    getBankDetails(): Observable<any>{
      return this.httpClient.get(this.url + 'orderBankDetails');
    }

    postSimulator(typeShipping: string, ammountSend: string, ammountReceived: string, codePromotion: string): Observable<any>{
      const data = {typeShipping, ammountSend, ammountReceived, codePromotion};
      return this.httpClient.post(this.url+'orderSimulator', data);
    }

    getOrderNumber(): Observable<any>{
        return this.httpClient.get(this.url+'orderNumber');
    }

    getAccountsTranzfer(): Observable<any>{
      return this.httpClient.get(this.url+'orderAccountsTranzfer');
    }

    postorderConstancy(data: any): Observable<any>{
      return this.httpClient.get(this.url+'orderConstancy', data);
    }

}
