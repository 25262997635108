import { Component, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderService } from '../../../../service/order.service';
import { finalize } from 'rxjs/operators';

import {OrderFakeDb} from '../../../../fake-db/order';

import { locale as english } from '../../../../../assets/i18n/en';
import { locale as espanish } from '../../../../../assets/i18n/es';
import { locale as portugues } from '../../../../../assets/i18n/br';
import {TranslationService} from '../../../../service/translation.service';
@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {


  accountForm: FormGroup;

  commission: number = 20;
  taxes: number = 10;
  discounts: number = 30;
  total: number = 40;
  exchangeRate: number = 3.67;

  selectFromCountry: any;
  selectToCountry: any;
  nameCountry: string;
  nametoCountry: string;
  countries = OrderFakeDb.country;

  selectFromDivisa: string;
  selectToDivisa: string;
  divisaTo = [];
  divisaFrom = [];
  account = [];

  selectedFromAccount;
  nameFromAccount;
  selectedToAccount;
  nameToaccount;
  sendAccount = [];

  data = [];
  dataConstancy = [];
  typeShipping = OrderFakeDb.type_shipping;
  codShipping;

  
  fromAmmount;
  toAmmount;
  codOrder: string;


  typeChange = [
    {
      id: 1,
      title: 'L 22',
      change: '3.44',
      img: 'arrow_downward'
    },
    {
      id: 2,
      title: 'M 22',
      change: '3.44',
      img: 'arrow_downward'
    },
    {
      id: 3,
      title: 'M 22',
      change: '3.44',
      img: 'arrow_downward'
    },
    {
      id: 4,
      title: 'J 22',
      change: '3.44',
      img: 'arrow_downward'
    },
    {
      id: 5,
      title: 'V 22',
      change: '3.44',
      img: 'arrow_downward'
    },
    {
      id: 6,
      title: 'S 22',
      change: '3.44',
      img: 'arrow_downward'
    },
    {
      id: 7,
      title: 'D 22',
      change: '3.44',
      img: 'arrow_downward'
    }
  ];

  questionsArray = [
    {
      id: 1,
      title: '¿Cúal es la garantía?',
      description: 'Somos una empresa legalmente constituída que cuenta con un proceso totalmente bancarizado y una política de devolución de fondos.'
    },
    {
      id: 2,
      title: '¿Cómo envío montos mayores?',
      description: 'Somos una empresa legalmente constituída que cuenta con un proceso totalmente bancarizado y una política de devolución de fondos.'
    },
    {
      id: 3,
      title: '¿Cuál es el horario de atención?',
      description: 'Somos una empresa legalmente constituída que cuenta con un proceso totalmente bancarizado y una política de devolución de fondos.'
    },
    {
      id: 4,
      title: '¿Cuál es el monto máximo?',
      description: 'Somos una empresa legalmente constituída que cuenta con un proceso totalmente bancarizado y una política de devolución de fondos.'
    },
    {
      id: 5,
      title: '¿Son confiables?',
      description: 'Somos una empresa legalmente constituída que cuenta con un proceso totalmente bancarizado y una política de devolución de fondos.'
    }
  ];

  showNext = false;
 
  hideItem = true;

  idItem = 9;

    /**
     * Constructor
     *
     * @param {TranslationService} _fuseTranslationLoaderService
     */
  constructor(
        private _formBuilder: FormBuilder,
        private _orderService: OrderService,
        private _fuseTranslationLoaderService: TranslationService) {
          this._fuseTranslationLoaderService.loadTranslations(english, espanish, portugues);
        }

  ngOnInit(): void {
     this.loadForm();
  }

  loadForm(){
        this.accountForm = this._formBuilder.group({
            fromCountry   : ['', [Validators.required]],
            fromAmmount: ['', Validators.required],
            fromBadge: ['', Validators.required],
            toCountry: ['', Validators.required],
            toAmmount: ['', Validators.required],
            toBadge: ['', Validators.required],
            fromAccount: ['', Validators.required],
            toAccount: ['', Validators.required],
            description: [''],
            codePromotion: ['']
        });
        this.loadData();
        this.loadControls();
  }

  loadData(){
      this.selectFromCountry = this.countries[0]['code_country'];
      this.nameCountry = this.countries[0]['name_country'];
      this.nametoCountry = this.countries[2]['name_country'];
      this.selectToCountry = this.countries[2]['code_country'];
      this.loadCurrency();
    /*this._orderService.getCountry().pipe(finalize(() => this.loadCurrency())).subscribe( dataCountry => {
      this.countries = dataCountry;
      this.selectFromCountry = this.countries[0]['code_country'];
      this.nameCountry = this.countries[0]['name_country'];
      this.nametoCountry = this.countries[2]['name_country'];
      this.selectToCountry = this.countries[2]['code_country'];
    });*/ 
    this.codShipping = this.typeShipping[0]['code_sent'];
    this.loadDetails();
    /*this._orderService.getTypeShipping().pipe(finalize(() => this.loadDetails() )).subscribe( dataShipping => {
      this.typeShipping = dataShipping;
      this.codShipping = dataShipping[0]['code_sent'];
    });*/
   
  }

  loadCurrency(){
    this.divisaFrom = this.countries[0]['currency'];
    this.selectFromDivisa = this.divisaFrom[0]['name_currency'];
    this.divisaTo = this.countries[2]['currency'];
    this.selectToDivisa = this.divisaTo[0]['name_currency'];
  }

  loadDetails(){
      const dataShipping = OrderFakeDb.bank_details;
      this.account = dataShipping[0]['accounts_sent'];
      this.sendAccount = dataShipping[0]['accounts_received'];
      this.selectedFromAccount = this.account[0]['code_account'];
      this.nameFromAccount = this.account[0]['name'];
      this.selectedToAccount = this.sendAccount[1]['code_account'];
      this.nameToaccount = this.sendAccount[1]['name'];
      this.exchangeRate = dataShipping[0]['exchange_rate'];
    /*this._orderService.getBankDetails().subscribe( dataShipping => {
      this.account = dataShipping[0]['accounts_sent'];
      this.sendAccount = dataShipping[0]['accounts_received'];
      this.selectedFromAccount = this.account[0]['code_account'];
      this.nameFromAccount = this.account[0]['name'];
      this.selectedToAccount = this.sendAccount[1]['code_account'];
      this.nameToaccount = this.sendAccount[1]['name'];
      this.exchangeRate = dataShipping[0]['exchange_rate'];
    });*/
  }

  loadControls(){
    this.accountForm.controls['fromCountry'].setValue(this.selectFromCountry);
    this.accountForm.controls['fromBadge'].setValue(this.selectFromDivisa);
    this.accountForm.controls['toCountry'].setValue(this.selectToCountry);
    this.accountForm.controls['toBadge'].setValue(this.selectToDivisa);
    this.accountForm.controls['fromAccount'].setValue(this.selectedFromAccount);
    this.accountForm.controls['toAccount'].setValue(this.selectedToAccount);
  }

  changeStep(codStep: number){
    this.codShipping = codStep;
  }

  submitRegister(form: any){
    if(!this.accountForm.invalid){
      console.log(form);
      this._orderService.getOrderNumber().subscribe(codOrder => {
        this.codOrder = codOrder[0]['order'];
        this.sendDataConstancy(form);
      });
    }
  }

  simulationAccount(montoEnviar: string, montoRecibir: string, codigoPromocion: string){
    this._orderService.postSimulator(this.codShipping, montoEnviar, 
      montoRecibir, codigoPromocion).subscribe(data => {
        this.commission = data.commission;
        this.taxes = data.taxes;
        this.discounts = data.discounts;
        this.total = data.total;
    });
  }

  codePromotions(codPro: number){
   this.simulationAccount(this.fromAmmount, "0", String(codPro));
  }

  sendDataConstancy(_form :any){
    this.data = [];
    _form.arrayCuenta = {
      'bank' : {
        'sendAccount': this.account,
        'received': this.sendAccount
      },
      'country': this.countries
    }
    _form.simulation =  {
      'order': this.codOrder,
      'commission':  this.commission,
      'taxes': this.taxes,
      'discounts':  this.discounts,
      'total':  this.total,
      'exchangeRate':  this.exchangeRate,
      'typeSend': this.codShipping !== 1 ? 'Normal' : 'Express' 
    };
    this.data.push(_form);
  }

  fromCountrySelect(data: any){
    const countries = this.countries.filter( z => z.code_country === Number(data));
    this.divisaFrom = [];
    this.divisaFrom = countries[0]['currency'];
    this.selectFromDivisa = this.divisaFrom[0]['name_currency'];
    this.selectFromCountry = countries[0]['code_country'];
    this.nameCountry = countries[0]['name_country'];
  }

  toCountrySelect(data: any){
    const tocountries = this.countries.filter( z => z.code_country === Number(data));
    this.divisaTo = tocountries[0]['currency'];
    this.selectToDivisa = this.divisaTo[0]['name_currency'];
    this.selectToCountry = tocountries[0]['code_country'];
    this.nametoCountry = tocountries[0]['name_country'];
  }

  fromAccountSelect(data: any){
    this.selectedFromAccount = Number(data);
    const accounts = this.account.filter(x=> x.code_account === Number(data));
    this.nameFromAccount = accounts[0]['name'];
  }

  toAccountSelect(data: any){
    this.selectedToAccount = Number(data);
    const accounts = this.sendAccount.filter(x=> x.code_account === Number(data));
    this.nameToaccount = accounts[0]['name'];
  }


  changeFromAmmount(val: any){
    console.log(val);
    this.fromAmmount = val;
    this.simulationAccount(val, "0" , "0");
  }

  changetoAmmount(val: any){
    console.log(val);
    this.toAmmount = val;
    this.simulationAccount(val, "0" , "0");
  }


  sendSummary(data: any)
  {
    this.dataConstancy = data;
    console.log(data);
  }

  changeNavigation(cod: number){

    switch(cod){
      case 0: 
          this.showNext = !this.showNext ? true : false;
      case 1:
        this.idItem = this.idItem !== cod ? cod : 9;
      console.log(cod);
      break;
      case 2:
        this.idItem = this.idItem !== cod ? cod : 9;
      console.log(cod);
      break;
      case 3:
        this.idItem = this.idItem !== cod ? cod : 9;
      console.log(cod);
      break;
      case 4:
        this.idItem = this.idItem !== cod ? cod : 9;
      console.log(cod);
      break;
      case 5:
        this.idItem = this.idItem !== cod ? cod : 9;
      console.log(cod);
      break;
      case 6:
        this.idItem = this.idItem !== cod ? cod : 9;
      console.log(cod);
      break;
    }
  }

}
